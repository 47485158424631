import {httpPost} from '@/plugins/request'

/**
 * 用户登录
 *
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiLogin(data = [])
{
    return httpPost('/admin/login', data)
}
